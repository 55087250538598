import React from 'react';
import SharedGallery from "./SharedGallery";
import './App.css';
import caifb from './CAI-FB-800.png';

// Sample filesData for demonstration
const filesData = [
  {
    id: 1,
    name: "Sample Image 1",
    url: "https://via.placeholder.com/150",
    thumbnailUrl: "https://via.placeholder.com/150"
  },
  {
    id: 2,
    name: "Sample Image 2",
    url: "https://via.placeholder.com/150",
    thumbnailUrl: "https://via.placeholder.com/150"
  }
  // Add more file objects as needed
];

function AppContent() {
  return (
      <div className="App">
        <SharedGallery filesData={filesData}/>
        <footer className="footer">
          <h2>Claim your own files, get File Baby now.</h2>
          <button title="Get File Baby Now!" tabIndex={"0"} className="try-it-now-btn" onClick={() => window.location.href = 'https://file.baby'}>
            Get File Baby Now!
          </button>
          <p><a href="https://file.baby">Privacy Policy & Terms of Use</a></p>
          <p>To inspect your content, use <a href="https://contentcredentials.org/verify" target="_blank" rel="noopener noreferrer">contentcredentials.org/verify</a></p>
          <p><img src={caifb} title="File Baby is a member of Content Authenticity Initiative" alt="File Baby is a member of Content Authenticity Initiative" className="responsive" /></p>
          <p>&copy; Copyright 2024, <a href="https://file.baby" alt="File Baby">File Baby</a>, All Rights Reserved</p>
        </footer>
      </div>

  );
}

function App() {
  return <AppContent />;
}

export default App;
