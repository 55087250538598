import React from 'react';
import './SharedGallery.css';

const audioPlaceholder = './audio_placeholder.png'; // Path to your placeholder

function SharedGallery() {
    const queryParams = new URLSearchParams(window.location.search);
    const filesParam = queryParams.get('files');
    const fileUrls = filesParam ? filesParam.split(',').map(file => decodeURIComponent(file)) : [];

    const isAudioFile = (url) => {
        const audioExtensions = ['mp3', 'wav', 'ogg', 'm4a', 'aac'];
        return audioExtensions.some(ext => url.endsWith(`.${ext}`));
    };

    const getFileName = (url) => {
        return url.substring(url.lastIndexOf('/') + 1);
    };


    return (
        <div className="shared-gallery">
            <h2>Shared Gallery</h2>
            {fileUrls.length > 0 ? (
                <div className="files">
                    {fileUrls.map((fileUrl, index) => {
                        const fileName = getFileName(fileUrl);
                        const handleShareFile = async (fileUrl) => {
                            try {
                                await navigator.clipboard.writeText(fileUrl);
                                alert('File URL copied to clipboard!');
                            } catch (err) {
                                console.error('Failed to copy:', err);
                            }
                        };
                        const verifyUrl = `https://contentcredentials.org/verify?source=${encodeURIComponent(fileUrl)}`; // Correctly define verifyUrl inside the map
                        return (
                            <div key={index} className="file-item">
                                <a href={fileUrl} target="_blank" rel="noopener noreferrer">
                                    <img
                                        src={isAudioFile(fileUrl) ? audioPlaceholder : fileUrl}
                                        alt={fileName}
                                        className="file-thumbnail"
                                    />
                                </a>
                                {/* Add the file name as a link below the image */}
                                <a href={fileUrl} target="_blank" rel="noopener noreferrer" className="file-name" title={fileName}>
                                    {fileName}
                                </a>

                                {/* Add the Verify link */}
                                <p><a href={verifyUrl} target="_blank" rel="noopener noreferrer">Verify</a></p>
                                <button tabIndex={"0"} title="Copy URL to Clipboard; Paste to Share." onClick={() => handleShareFile(fileUrl)}>Share File</button>

                            </div>
                        );
                    })}

                </div>
            ) : (
                <div className="shared-gallery-error">No files selected for this gallery.</div>
            )}
        </div>
    );
}

export default SharedGallery;
